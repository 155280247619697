export default function cutText() {
    const targets = document.querySelectorAll('.cut-text-wrapper')

    targets.forEach(target => {
        target.append(getButton())
    })

    function getButton() {
        const button = document.createElement('button');
        button.className = 'cut-text-action'
        button.innerText = 'mehr anzeigen'

        button.addEventListener('click', expandText)

        return button
    }

    function expandText(e) {
        const target = e.target
        console.log(target)
        const sibling = target.closest('div')
        sibling.classList.toggle('active')
        target.classList.toggle('active')
        addJustButtonText(target)
    }

    function addJustButtonText(button) {
        button.innerText = ''
        if (button.classList.contains('active'))
            button.innerText = 'schließen'
        else button.innerText = 'mehr anzeigen'
    }

}
