export default function menu() {

    const mainMenu = document.querySelector('.main-nav div.menu')
    const menuTriggers = document.querySelectorAll('.main-nav .burger')
    const menuIcons = document.querySelectorAll('.main-nav .burger .material-icons')


    menuTriggers.forEach(trigger => {
        trigger.addEventListener('click', () => {
            mainMenu.classList.toggle('closed')
            window.setTimeout(toggleIcons, 750)
        })
    })


    function toggleIcons() {
        menuIcons.forEach(icon => {
            icon.classList.toggle('active')
        })
    }

    function menuDot() {
        const menuItems = document.querySelectorAll('.main-menu li')
        const activeItemDot = document.querySelector('.active-item-dot')
        const widthOffset = 32
        const activeIndex = getActiveIndex()
        console.log(activeIndex)

        animateDot(menuItems[activeIndex])

        menuItems.forEach(item => {
            item.addEventListener('click', () => {
                resetActiveState()
                hasSubMenu(item)
                console.log(item.getBoundingClientRect())
                item.classList.add('active')
                animateDot(item)
            })

        })

        function resetActiveState() {
            menuItems.forEach(item => {
                item.classList.remove('active')
            })
        }

        function animateDot(item) {
            let pos = item.getBoundingClientRect()
            const heightOffset = item.clientHeight / 2 - 5
            console.log(pos.top)
            console.log(pos.left)
            // while (pos.left > 500) {
            //     pos = item.getBoundingClientRect()
            // }
            activeItemDot.style.top = (pos.top + heightOffset) + 'px'
            activeItemDot.style.left = pos.left - widthOffset + 'px'
        }

        function getActiveIndex() {

            var activeIndex = false
            menuItems.forEach((item, index) => {
                if (item.classList.contains('active'))
                    activeIndex = index
            })
            return activeIndex
        }

        function hasSubMenu(item) {
            if (item.classList.contains('has-sub-menu')) {
                const submenu = item.querySelector('.sub-menu')
            }
        }
    }
}
