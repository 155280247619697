export default function popUp(id) {
  const popup = document.querySelector('#' + id + ' .popup')
  if (popup === null) {
    return
  }
  const close = popup.querySelector('.close')

  console.log(popup)
  console.log(close)

  close.addEventListener('click', () => {
    popup.classList.remove('active')
    console.log('CLOSE')
  })

}