export default function video() {
    const videoEls = document.querySelectorAll('.aerzte .img.video ')
    const videoHook = document.querySelector('#hook')
    console.log(videoEls)


    videoEls.forEach(video => {
        video.addEventListener('click', () => {
            showOverlay(video.dataset.src)
        })
    })

    function showOverlay(src) {

        console.log(src)
        const iFrame = `
        <div class="overlay" style="padding:56.25% 0 0 0;">
            <div class="close">
            <span class="material-icons mr-3">close</span>
            <span>Schließen</span>

</div>
            <iframe src="${src}"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
                style="position:absolute;transform:translate(-50%,-50%);top:55%;left:50%;width:80%;height:80%;"
                title="MPK_Bischoff_Final.mp4">
            </iframe>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
        `
        videoHook.innerHTML = (iFrame)

        const hide = document.querySelectorAll('.overlay .close, .overlay')
        hide.forEach(el => {
            el.addEventListener('click', hideOverlay)
        })
    }

    function hideOverlay() {
        videoHook.innerHTML = "";
    }
}
