function fontSize() {
  const small = document.querySelector('.main-nav .fontSize #small')
  const normal = document.querySelector('.main-nav .fontSize #normal')
  const big = document.querySelector('.main-nav .fontSize #big')
  const root = document.querySelector('body')
  let fontSize
  if (cookieExists()) {
    fontSize = parseInt(cookieExists())
  }
  else {
    fontSize = parseInt(
        window.getComputedStyle(root).getPropertyValue('font-size'))
  }
  let adaptFontSize = parseInt(fontSize)

  setFontsize()
  console.log(fontSize)
  small?.addEventListener('click', smallFontSize)
  big?.addEventListener('click', bigFontSize)
  normal?.addEventListener('click', resetFontSize)

  function cookieExists() {
    const cookies = document.cookie.split('; ')
    cookies.forEach(c => {
      if (c.startsWith('fontSize')) {
        return c.split('=')[1]
      }
    })
    return false
  }

  function smallFontSize() {
    console.log('click small')
    adaptFontSize--
    setFontsize()
    setCookie()
  }

  function bigFontSize() {
    console.log('click big')
    adaptFontSize++
    setFontsize()
    setCookie()
  }

  function setFontsize(size = adaptFontSize) {
    console.log(size)
    root.style = 'font-size:' + size + 'px'
    setCookie()
  }

  function resetFontSize() {
    console.log('RESET')
    adaptFontSize = parseInt(fontSize)
    root.style = 'font-size:' + fontSize
    setCookie()
  }

  function setCookie() {
    const cookie = 'fontSize=' + adaptFontSize
    document.cookie = cookie
  }
}

export default fontSize
