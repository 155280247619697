export default function dachStandorte() {
  console.log('DACH STANDORTE')
  const triggers = document.querySelectorAll(
      'section.standorte .standorte-header')
  const element = document.querySelector('section.standorte .standorte-content')

  console.log(triggers)
  console.log(element)

  triggers.forEach(trigger => {
    trigger.addEventListener('click', () => {
      element.classList.toggle('active')
    })
  })
}
