export default function dachMenu() {
    const triggers = document.querySelectorAll('.menu-trigger, nav.main-nav ul li a')
    const menu = document.querySelector('nav.main-nav')

    triggers.forEach(trigger => {
        trigger.addEventListener('click', () => {
            menu.classList.toggle('active')
        })
    })
}
