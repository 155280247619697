export default function kontakt() {
    const trigger = document.querySelectorAll('section.kontakt .wrapper .close, div.main-nav .kontakt.mobile')
    const overlay = document.querySelector('section.kontakt')
    console.log(trigger)

    trigger.forEach(el => {
        el.addEventListener('click', () => {
            overlay.classList.toggle('closed')
        })
    })
}
