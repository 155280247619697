export default function scrollTop() {
    const target = document.querySelector('footer .top')
    console.log(target)
    target.addEventListener('click', scrollToTop)


    function scrollToTop() {
        console.log('SCROLL TOP')
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
}
