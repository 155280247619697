import menu from './Functions/menu'
import cutText from './Functions/cutText'
import iconList from './Functions/iconList'
import scrollTop from './Functions/scrollTop'
import newsSlider from './Functions/newsSlider'
import accordion from './Functions/accordion'
import kontakt from './Functions/kontakt'
import dachStandorte from './Functions/dach_standorte'
import dachMenu from './Functions/dachMenu'
import video from './Functions/video'
import fontSize from './Functions/fontSize'
import popUp from './Functions/popUp'

fontSize()
scrollTop()
iconList()
cutText()
newsSlider()
accordion()
menu()
kontakt()

dachStandorte()
dachMenu()
video()
popUp('geaenderte-sprechzeiten')

