export default function newsSlider() {
    const d = document;
    const items = d.querySelectorAll('.news-wrapper .news');
    const controlls = d.querySelectorAll('.slider-controlls.news button')

    controlls.forEach((controll, index) => {
        controll.addEventListener('click', () => {
            console.log('CLICK', index)
            resetControlls()
            controll.classList.add('active')
            setTransform(index)
        })
    })


    function resetControlls() {
        controlls.forEach(controll => {
            controll.classList.remove('active')
        })
    }

    function setTransform(offset) {
        offset = -offset
        items.forEach(item => {
            item.style.transform = `translateX(${offset * 100}%`
        })
    }
}
